<template>
   <div class="wrapper">
     <headBack>
       <template v-slot:title>
         <div class='title'>
           购买协议
         </div>
       </template>
     </headBack>
     <div class='header_cont'></div>
     <div class="main">
       <div class="title">  购买须知：</div>
       <div class="sub tit">尊敬的患者及家属（以下单独或共同称为“用户”）：</div>
       <div>感谢您选择医护到家！为更好的给您提供上门服务，请务必仔细阅读如下服务条款：</div>
       <div class="sub">平台责任义务</div>
       <div>1.核实、记录用户的真实情况；</div>
       <div>2.审核护士资质，确保平台入驻护士均具有相关资质；</div>
       <div>3.安排、协调护士上门服务；</div>
       <div>4.监督、检查护士上门服务，接受用户的咨询和投诉，并对投诉作出调查和处理；</div>
       <div>5.处理用户提出的意见和建议，完善平台制度.</div>
       <div class="sub">用户的责任义务</div>
       <div>1.自备处方，院外护理证明，相关工具和药物，并对其真实性负责;</div>
       <div>2.详细查看、了解并接受平台的服务介绍和规定；</div>
       <div>3.遵守平台的规定，接受违反规定的惩处措施.</div>
       <div class="sub">治疗潜在风险</div>
       <div>平台和护士告知用户任何治疗服务都可能发生的一些风险，有些不常见的风险可能没有在此列出，具体的风险因不同病人（患者）及疾病状态有所不同.</div>
       <div>1.用户理解任何护理操作都存在风险</div>
       <div>2.用户理解任何所用药物都可能产生副作用，包括轻度的恶心、皮疹等症状到严重的过敏性休克，甚至危及生命，且药物是用户自备，用户对药物的情况负责.</div>
       <div>3.用户理解此护理操作以及使用的相关药物可能发生的风险，并自愿接受此类风险：</div>
       <div>1)过敏反应;</div>
       <div>2)头痛及其他组织的疼痛;</div>
       <div>3)皮肤粘膜损害；瘙痒，皮疹;</div>
       <div>4)红肿，出血;</div>
       <div>5)漏液（包括相关体液）;</div>
       <div>6)麻木、针刺感、烧灼感、手足疼痛;</div>
       <div>7)恶心呕吐;</div>
       <div>8)感染，发热;</div>
       <div>9)胸闷，呼吸困难，窒息;</div>
       <div>10)其他病症.</div>
       <div class="sub">患者知情选择</div>
       <div>用户接受平台的有关规定，如有违反接受平台的处理措施。</div>
       <div>用户并未得到任何百分百操作成功的许诺。</div>
       <div>以上情况用户已了解，用户坚持要求护士为患者进行服务操作，出现相关问题或者风险由用户自己负责，与护士或者平台无关.</div>
       <div class="sub">授权说明：</div>
       <div>作为接受本次医护到家服务的患者，如由于身体情况无法签字、确认的，患者特委托家属在本次服务过程中代为签字、确认。在本次服务中，对被委托人签字、确认的所有相关文件，患者均予以认可，并承担相应的法律责任。</div>
       <div class="sub">特别提示：</div>
       <div>为提升服务质量，请您在每次服务后，对提供上门服务护士的服务质量、服务态度等进行真实、客观的评价，感谢您的支持。祝您早日康复！</div>
       <div class="sub">我已仔细阅读上文并理解其含义，经慎重考虑，我同意通过《医护到家》预约护士为我（或我的家属）进行上门服务。</div>
     </div>
   </div>
</template>

<script>
export default {
  name: "goumaixieyi"
}
</script>

<style scoped lang="scss">
.main{
  box-sizing: border-box;
  padding:30px;
  font-size: 26px;
  color:#666;
  div{
    line-height: 44px;
  }
  .sub,.tit,.title{
    color:#333;
    font-weight: bold;
  }
}
</style>

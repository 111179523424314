import { render } from "./goumaixieyi.vue?vue&type=template&id=75fd24a0&scoped=true"
import script from "./goumaixieyi.vue?vue&type=script&lang=js"
export * from "./goumaixieyi.vue?vue&type=script&lang=js"

import "./goumaixieyi.vue?vue&type=style&index=0&id=75fd24a0&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-75fd24a0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "75fd24a0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('75fd24a0', script)) {
    api.reload('75fd24a0', script)
  }
  
  module.hot.accept("./goumaixieyi.vue?vue&type=template&id=75fd24a0&scoped=true", () => {
    api.rerender('75fd24a0', render)
  })

}

script.__file = "src/views/static/agreement/goumaixieyi.vue"

export default script